import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import Modal from './Modal';
import AuthModal from './AuthModal';
import { supabase } from '../supabaseClient';
import { FaMask, FaChevronRight } from 'react-icons/fa'; // Changed FaDice to FaMask
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const fontClasses = [
  'font-arima',
];

const getFontClass = (index) => {
  return fontClasses[index % fontClasses.length];
};

// Add this SlickArrow component
const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props} className="text-white hover:text-gray-300 focus:outline-none transition-colors duration-200">
    {children}
  </button>
);

// Utility function to shuffle an array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

function MainContent({ session, openModal, globalSearchTerm }) {
  const [companions, setCompanions] = useState([]);
  const [shuffledCompanions, setShuffledCompanions] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompanions();
  }, []);

  const fetchCompanions = async () => {
    const { data, error } = await supabase
      .from('companions')
      .select('id, name, description, display_image, categories, is_public');

    if (error) {
      console.error('Error fetching companions:', error);
    } else {
      setCompanions(data);
      // Shuffle the companions only once after fetching
      setShuffledCompanions(shuffleArray(data.filter(companion => companion.is_public)));
    }
  };

  const filteredCompanions = shuffledCompanions.filter(companion =>
    companion.name.toLowerCase().includes(globalSearchTerm.toLowerCase()) ||
    companion.description.toLowerCase().includes(globalSearchTerm.toLowerCase())
  );

  const organizeCompanionsByCategory = (companions) => {
    if (globalSearchTerm) {
      return { 'Search results': companions };
    }

    const categorized = {
      'Popular': [],
      'Girlfriend': [],
      'Boyfriend': [],
      'Coach': [],
      'Advice': [],
      'Adult Entertainment': [],
    };
    
    companions.forEach(companion => {
      companion.categories.forEach(category => {
        if (category in categorized) {
          categorized[category].push(companion);
        } else {
          if (!categorized[category]) {
            categorized[category] = [];
          }
          categorized[category].push(companion);
        }
      });
    });

    // Sort the Popular category to show Elizabeth, Sophia, Alicia, and Hana first
    if (categorized['Popular'].length > 0) {
      const priorityOrder = ['Cassie', 'Elizabeth', 'Mia', 'Alicia'];
      categorized['Popular'].sort((a, b) => {
        const indexA = priorityOrder.indexOf(a.name);
        const indexB = priorityOrder.indexOf(b.name);
        if (indexA !== -1 && indexB !== -1) return indexA - indexB;
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;
        return 0;
      });
    }

    return categorized;
  };

  const categorizedCompanions = organizeCompanionsByCategory(filteredCompanions);
  const priorityCategories = ['Popular', 'Girlfriend', 'Boyfriend', 'Coach', 'Advice', 'Adult Entertainment'];
  const otherCategories = Object.keys(categorizedCompanions)
    .filter(cat => !priorityCategories.includes(cat) && cat !== 'Search results')
    .sort();
  
  const categories = globalSearchTerm 
    ? ['Search results'] 
    : [...priorityCategories, ...otherCategories];

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0px',
          arrows: false,
          swipe: true,
          freeScroll: true,
        }
      }
    ]
  };

  const getSliderSettings = (companionsCount) => {
    const isMobile = window.innerWidth <= 640;
    return {
      ...sliderSettings,
      infinite: !isMobile && companionsCount > sliderSettings.slidesToShow,
      arrows: !isMobile && companionsCount > sliderSettings.slidesToShow,
    };
  };

  const handleFeelingLucky = () => {
    if (companions.length > 0) {
      const randomCompanion = companions[Math.floor(Math.random() * companions.length)];
      window.location.href = `/chat/${randomCompanion.id}`;
    }
  };

  const handleCategoryClick = (category) => {
    navigate(`/category/${encodeURIComponent(category.toLowerCase())}`);
  };

  return (
    <div className="main-content flex-1 bg-black text-white overflow-y-auto flex flex-col min-h-screen relative">
      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        .animate-fade-in {
          animation: fadeIn 0.3s ease-out forwards;
        }
        @media (max-width: 640px) {
          .main-content-inner {
            padding-left: 0;
            padding-right: 0;
          }
          .carousel-container {
            margin-left: -1rem;
            margin-right: -1rem;
          }
        }
      `}</style>
      <div className="main-content-inner max-w-7xl mx-auto w-full flex-grow px-4 sm:px-6 lg:px-8 pt-0 sm:pt-8">
        {categories.map(category => (
          categorizedCompanions[category] && categorizedCompanions[category].length > 0 && (
            <div key={category} className="mb-8">
              <div className="flex items-center justify-between mb-4">
                <button 
                  onClick={() => handleCategoryClick(category)}
                  className="group flex items-center space-x-2 focus:outline-none"
                >
                  <h2 className="text-2xl sm:text-3xl font-bold capitalize group-hover:text-gray-300 transition-colors duration-200 flex items-center">
                    {category} 
                    {/* Remove the category count */}
                    {/* <span className="hidden sm:inline-block text-sm sm:text-base font-normal text-gray-400 ml-2 mt-2 group-hover:hidden transition-opacity duration-200">
                      ({categorizedCompanions[category].length})
                    </span> */}
                    <span className="hidden sm:hidden sm:group-hover:inline-flex text-sm sm:text-base font-normal text-gray-400 ml-2 mt-2 items-center animate-fade-in">
                      Explore All <FaChevronRight className="ml-1" />
                    </span>
                    <span className="sm:hidden text-sm font-normal text-gray-400 ml-2 mt-2 flex items-center">
                      Explore All <FaChevronRight className="ml-1" />
                    </span>
                  </h2>
                </button>
                {category !== 'Search results' && categorizedCompanions[category].length > sliderSettings.slidesToShow && (
                  <div className="hidden sm:flex items-center space-x-2">
                    <SlickArrow
                      onClick={() => document.querySelector(`.${category}-slider .slick-prev`).click()}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                      </svg>
                    </SlickArrow>
                    <SlickArrow
                      onClick={() => document.querySelector(`.${category}-slider .slick-next`).click()}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                      </svg>
                    </SlickArrow>
                  </div>
                )}
              </div>
              {(category === 'Search results') ? (
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-4 md:gap-5 lg:gap-6">
                  {categorizedCompanions[category].map((companion, index) => (
                    <div key={companion.id}>
                      <CompanionCard companion={companion} index={index} />
                    </div>
                  ))}
                </div>
              ) : (
                <div className={`${category}-slider carousel-container overflow-x-auto sm:overflow-x-visible`}>
                  <Slider {...getSliderSettings(categorizedCompanions[category].length)}>
                    {categorizedCompanions[category].map((companion, index) => (
                      <div key={companion.id} className="px-0.5 sm:px-1">
                        <CompanionCard companion={companion} index={index} />
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          )
        ))}
      </div>

      <footer className="mt-auto pt-8 pb-4 text-right bg-black pr-4 sm:pr-6 lg:pr-8">
        <nav className="inline-flex space-x-6">
          <a href="/terms" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors duration-300">Terms</a>
          <a href="/privacy" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors duration-300">Privacy</a>
        </nav>
      </footer>

      {/* Slightly Larger Mystery Chat Button */}
      <div className="fixed left-1/2 transform -translate-x-1/2 bottom-5 sm:bottom-7" style={{ zIndex: 1000 }}>
        <button
          onClick={handleFeelingLucky}
          className="bg-white text-[#E50914] font-bold py-2.5 px-6 sm:py-3.5 sm:px-8 rounded-full shadow-lg transition-all duration-300 text-lg sm:text-xl flex items-center justify-center space-x-3 whitespace-nowrap hover:bg-[#E50914] hover:text-white group relative overflow-hidden cursor-pointer"
          style={{
            boxShadow: '0 0 25px rgba(229, 9, 20, 0.6), 0 0 15px rgba(255, 255, 255, 0.4)',
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-[#E50914] to-[#FF5722] opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
          <FaMask className="text-2xl sm:text-3xl relative z-10" />
          <span className="relative z-10 font-bold tracking-wide">MYSTERY CHAT</span>
          <div className="absolute top-0 left-0 w-full h-full bg-white opacity-0 group-hover:opacity-20 transition-opacity duration-300 transform scale-x-0 group-hover:scale-x-100 origin-left"></div>
        </button>
      </div>
    </div>
  );
}

// Updated CompanionCard component
const CompanionCard = ({ companion, index }) => (
  <Link to={`/chat/${companion.id}`} className="block">
    <div className="relative rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 group" style={{ paddingBottom: '150%' }}>
      <img 
        src={companion.display_image} 
        alt={companion.name} 
        className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
      <div className="absolute inset-x-0 bottom-0 p-2 sm:p-3 text-center">
        <h2 className={`text-xl sm:text-2xl md:text-3xl font-bold text-white ${getFontClass(index)}`}>
          {companion.name.toUpperCase()}
        </h2>
        <div className="w-4 h-1 bg-white mx-auto my-1 sm:my-2"></div>
        <p className="text-xs sm:text-sm font-light text-gray-200 line-clamp-2 group-hover:line-clamp-none transition-all duration-300">
          {companion.description}
        </p>
      </div>
    </div>
  </Link>
);

// At the end of the file, add this line:
export { CompanionCard };

export default MainContent;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ImageViewModal from './ImageViewModal';
import ConfirmationModal from './ConfirmationModal';
import { IoSend, IoEllipsisVertical, IoSearch, IoCall, IoRefresh } from "react-icons/io5";
import './Chat.css'; // Add this import

// Update these components for status indicators
const SendingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M3.5 8.5L6 11l6-6" stroke="currentColor" strokeWidth="1.5" fill="none"/>
  </svg>
);

const SentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M1.5 8.5L4 11l6-6" stroke="currentColor" strokeWidth="1.5" fill="none"/>
    <path d="M5.5 8.5L8 11l6-6" stroke="currentColor" strokeWidth="1.5" fill="none"/>
  </svg>
);

const ReadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#34D399" viewBox="0 0 16 16">
    <path d="M1.5 8.5L4 11l6-6" stroke="#34D399" strokeWidth="1.5" fill="none"/>
    <path d="M5.5 8.5L8 11l6-6" stroke="#34D399" strokeWidth="1.5" fill="none"/>
  </svg>
);

// Update this function at the top of the file, outside of the Chat component
const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit', hour12: true });
};

// Add this function at the top of the file, outside of the Chat component
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return 'Today';
  } else if (date.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  } else {
    return date.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  }
};

// Add this new component for the sticky date header
const StickyDateHeader = ({ date }) => (
  <div className="sticky top-0 z-10 flex justify-center my-2">
    <div className="bg-gray-700 text-gray-300 text-xs px-2 py-1 rounded-full shadow-md">
      {date}
    </div>
  </div>
);

// Add this new function at the top of the file, outside of the Chat component
const highlightText = (text, query) => {
  if (!query) return text;
  const parts = text.split(new RegExp(`(${query})`, 'gi'));
  return parts.map((part, index) => 
    part.toLowerCase() === query.toLowerCase() 
      ? <mark key={index} className="bg-yellow-300 text-gray-900">{part}</mark>
      : part
  );
};

// Update this component for better visibility and loading state
const PlayButton = ({ onClick, isPlaying, isLoading }) => (
  <button
    onClick={onClick}
    className="ml-2 text-blue-400 hover:text-blue-500 focus:outline-none transition-colors duration-200"
    disabled={isLoading}
  >
    {isLoading ? (
      <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    ) : isPlaying ? (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
      </svg>
    )}
  </button>
);

// First, add this new component at the top of the file, after the imports

const TypingAnimation = () => (
  <div className="flex items-center space-x-1">
    <div className="w-2 h-2 rounded-full bg-gray-400 animate-bounce" style={{ animationDelay: '0ms' }}></div>
    <div className="w-2 h-2 rounded-full bg-gray-400 animate-bounce" style={{ animationDelay: '150ms' }}></div>
    <div className="w-2 h-2 rounded-full bg-gray-400 animate-bounce" style={{ animationDelay: '300ms' }}></div>
  </div>
);

// Replace the existing ShimmeringImagePlaceholder component with this new version

const ShimmeringImagePlaceholder = () => (
  <div className="w-48 h-48 bg-gray-800 rounded-lg overflow-hidden relative">
    <div className="absolute inset-0">
      <div className="shimmer-wrapper">
        <div className="shimmer"></div>
      </div>
    </div>
    <svg className="absolute inset-0 w-full h-full text-gray-700" fill="currentColor" viewBox="0 0 24 24">
      <path d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2zm0 2v10h16V6H4zm11 9h-1v-1h1v1zm2 0h-1v-1h1v1zm2 0h-1v-1h1v1z"/>
      <circle cx="8" cy="10" r="3"/>
    </svg>
  </div>
);

function Chat({ session, openModal, openPricingModal }) {
  const { companionId } = useParams();
  const [companion, setCompanion] = useState(null);
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [lastTypingMessageId, setLastTypingMessageId] = useState(null);
  const messagesEndRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentSearchIndex, setCurrentSearchIndex] = useState(-1);
  const searchRef = useRef(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isClearingChat, setIsClearingChat] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();
  const chatStarted = useRef(false);
  const [isStarterMessageLoading, setIsStarterMessageLoading] = useState(false);
  const [starterMessage, setStarterMessage] = useState('');
  const [playingMessageId, setPlayingMessageId] = useState(null);
  const [loadingMessageId, setLoadingMessageId] = useState(null);
  const [showStarterMessage, setShowStarterMessage] = useState(true);
  const [userCredits, setUserCredits] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);

  useEffect(() => {
    fetchCompanion();
    if (session) {
      fetchChatMessages();
      fetchUserCredits();
      setShowStarterMessage(false);
      
      // Set up real-time subscription for both INSERT and UPDATE events
      const channel = supabase
        .channel('custom-all-channel')
        .on(
          'postgres_changes',
          { 
            event: 'INSERT', 
            schema: 'public', 
            table: 'chats',
            filter: `companion_id=eq.${companionId}` 
          },
          handleNewMessage
        )
        .on(
          'postgres_changes',
          { 
            event: 'UPDATE', 
            schema: 'public', 
            table: 'chats',
            filter: `companion_id=eq.${companionId}` 
          },
          handleUpdatedMessage
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    } else {
      // For non-logged in users, just show the starter message
      setShowStarterMessage(true);
      setChatMessages([]); // Clear any existing messages
      setIsLoading(false); // Ensure loading state is false
    }
  }, [companionId, session]);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleNewMessage = (payload) => {
    console.log('New message received:', payload);
    const newMessage = payload.new;
    if (newMessage && newMessage.companion_id === companionId) {
      setChatMessages((prevMessages) => {
        let updatedMessages = [...prevMessages];
        if (newMessage.sender_type === 'user') {
          // Check if there's a pending message with the same content
          const pendingMessageIndex = updatedMessages.findIndex(
            msg => msg.pending && msg.sender_type === 'user' && msg.content.text === newMessage.content.text
          );
          if (pendingMessageIndex !== -1) {
            // Replace the pending message with the confirmed one
            updatedMessages[pendingMessageIndex] = { ...newMessage, status: newMessage.status || 'sent' };
          } else {
            // If no pending message found, add the new message
            updatedMessages.push({ ...newMessage, status: newMessage.status || 'sent' });
          }
        } else if (newMessage.sender_type === 'companion') {
          // Mark the last user message as 'read'
          const lastUserMessageIndex = updatedMessages.map(m => m.sender_type).lastIndexOf('user');
          if (lastUserMessageIndex !== -1) {
            updatedMessages[lastUserMessageIndex] = {
              ...updatedMessages[lastUserMessageIndex],
              status: 'read'
            };
          }
          // Add the companion message
          updatedMessages.push(newMessage);
        }
        return updatedMessages;
      });
      
      if (newMessage.sender_type === 'companion' && newMessage.status === 'typing') {
        setIsTyping(true);
        setLastTypingMessageId(newMessage.id);
      } else {
        setIsTyping(false);
        setLastTypingMessageId(null);
      }
    }
  };

  const handleUpdatedMessage = (payload) => {
    console.log('Message updated:', payload);
    const updatedMessage = payload.new;
    if (updatedMessage && updatedMessage.companion_id === companionId) {
      setChatMessages((prevMessages) => 
        prevMessages.map(msg => 
          msg.id === updatedMessage.id ? { ...updatedMessage, status: updatedMessage.status || msg.status } : msg
        )
      );
      if (updatedMessage.status === 'typing') {
        setIsTyping(true);
        setLastTypingMessageId(updatedMessage.id);
      } else if (updatedMessage.id === lastTypingMessageId) {
        setIsTyping(false);
        setLastTypingMessageId(null);
      }
    }
  };

  const fetchCompanion = async () => {
    setIsLoading(true);
    const { data, error } = await supabase
      .from('companions')
      .select('*')
      .eq('id', companionId)
      .single();

    if (error) {
      console.error('Error fetching companion:', error);
    } else {
      setCompanion(data);
    }
    setIsLoading(false);
  };

  // Add this new function
  const startNewChat = async () => {
    setIsStarterMessageLoading(true);
    try {
      await supabase.functions.invoke('start-chat', {
        body: JSON.stringify({
          companionId,
          userId: session.user.id,
        }),
      });
    } catch (error) {
      console.error('Error starting new chat:', error);
    } finally {
      setIsStarterMessageLoading(false);
    }
  };

  const fetchChatMessages = async () => {
    const { data, error } = await supabase
      .from('chats')
      .select('*')
      .eq('companion_id', companionId)
      .eq('is_deleted', false)
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching chat messages:', error);
    } else {
      const updatedData = data.reduce((acc, msg, index) => {
        if (msg.sender_type === 'user') {
          // Check if there's any companion message after this user message
          const nextMsg = data[index + 1];
          if (nextMsg && nextMsg.sender_type === 'companion') {
            acc.push({ ...msg, status: 'read' });
          } else {
            acc.push({ ...msg, status: msg.status || 'sent' });
          }
        } else {
          acc.push(msg);
        }
        return acc;
      }, []);

      setChatMessages(updatedData);

      // Only start a new chat if there are no messages and we haven't started one yet
      if (updatedData.length === 0 && !chatStarted.current) {
        chatStarted.current = true;
        startNewChat();
      }
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!message.trim() || isLoading) return;

    // Check if the user is logged in
    if (!session) {
      openModal(true);
      return;
    }

    setIsLoading(true);
    setError(null);

    const messageText = message.trim();
    setMessage('');

    // Create a pending message
    const pendingMessage = {
      id: `pending-${Date.now()}`, // Temporary ID
      companion_id: companionId,
      user_id: session.user.id,
      sender_type: 'user',
      content: { text: messageText },
      created_at: new Date().toISOString(),
      pending: true
    };

    // Add the pending message to the chat state
    setChatMessages(prevMessages => [...prevMessages, pendingMessage]);

    try {
      await supabase.functions.invoke('handle-message', {
        body: JSON.stringify({
          companionId,
          userId: session.user.id,
          message: { content: { text: messageText } },
        }),
      });
    } catch (err) {
      console.error('Error sending message:', err);
      setError('Failed to send message. Please try again.');
      // Remove the pending message if there's an error
      setChatMessages(prevMessages => prevMessages.filter(msg => msg.id !== pendingMessage.id));
      setMessage(messageText); // Restore the message in the input field if there's an error
    } finally {
      setIsLoading(false);
    }

    setShowStarterMessage(false);  // Hide the starter message when user sends a message
  };

  const formatMessage = (text, query = '') => {
    // Replace **bold** with <strong>bold</strong>
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    // Replace *italic* with <em>italic</em>
    text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
    
    // Highlight the search query
    if (query) {
      const regex = new RegExp(`(${query})`, 'gi');
      text = text.replace(regex, '<mark class="bg-yellow-300 text-gray-900">$1</mark>');
    }
    
    return text;
  };

  const renderMessageStatus = (msg) => {
    if (msg.sender_type === 'user') {
      if (msg.pending) return <SendingIcon />;
      if (msg.status === 'sent') return <SentIcon />;
      if (msg.status === 'read') return <ReadIcon />;
    }
    return null;
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  // Add these new functions
  const handleSearchClick = () => {
    setIsSearching(!isSearching);
    if (isSearching) {
      setSearchQuery('');
      setSearchResults([]);
      setCurrentSearchIndex(-1);
    }
  };

  const handlePrevSearch = () => {
    setCurrentSearchIndex((prevIndex) => 
      prevIndex > 0 ? prevIndex - 1 : searchResults.length - 1
    );
  };

  const handleNextSearch = () => {
    if (searchResults.length > 0) {
      setCurrentSearchIndex((prevIndex) => 
        prevIndex < searchResults.length - 1 ? prevIndex + 1 : 0
      );
      // Use setTimeout to ensure the state has updated before scrolling
      setTimeout(scrollToHighlightedMessage, 0);
    }
  };

  const handleCallClick = () => {
    // Placeholder function for call functionality
    console.log('Call clicked');
  };

  // Add this new function inside the Chat component
  const handleSearch = useCallback(() => {
    if (!searchQuery.trim()) {
      setSearchResults([]);
      setCurrentSearchIndex(-1);
      return;
    }

    const results = chatMessages.reduce((acc, msg, index) => {
      let content = '';
      if (msg.content && typeof msg.content === 'object' && msg.content.text) {
        content = msg.content.text;
      } else if (typeof msg.content === 'string') {
        content = msg.content;
      }

      if (content.toLowerCase().includes(searchQuery.toLowerCase())) {
        acc.push({ index, message: msg });
      }
      return acc;
    }, []);

    setSearchResults(results);
    setCurrentSearchIndex(results.length > 0 ? 0 : -1);
  }, [searchQuery, chatMessages]);

  // Add this effect to trigger search when query changes
  useEffect(() => {
    handleSearch();
  }, [searchQuery, handleSearch]);

  // Add this new useEffect hook for handling clicks outside the search area
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearching(false);
        setSearchQuery('');
        setSearchResults([]);
        setCurrentSearchIndex(-1);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Add this new function to handle Enter key press
  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleNextSearch();
    }
  };

  // Add this new function to scroll to the highlighted message
  const scrollToHighlightedMessage = () => {
    const highlightedMessage = document.querySelector('.highlighted-message');
    if (highlightedMessage) {
      highlightedMessage.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }
  };

  const handleClearChat = () => {
    setIsConfirmationModalOpen(true);
  };

  const confirmClearChat = async () => {
    setIsClearingChat(true);
    try {
      const { error } = await supabase.functions.invoke('clear-chat', {
        body: JSON.stringify({
          companionId,
          userId: session.user.id,
        }),
      });

      if (error) throw error;

      // Fade out existing messages
      const chatContainer = document.querySelector('.chat-messages-container');
      chatContainer.classList.add('opacity-0', 'transition-opacity', 'duration-500');

      // Wait for fade out animation
      await new Promise(resolve => setTimeout(resolve, 500));

      // Redirect to the home page
      navigate('/');
    } catch (error) {
      console.error('Error clearing chat:', error);
      setError('Failed to clear chat. Please try again.');
    } finally {
      setIsClearingChat(false);
      setIsConfirmationModalOpen(false);
    }
  };

  useEffect(() => {
    if (companion) {
      // For non-logged in users, just set the starter message directly
      if (!session) {
        setStarterMessage(companion.starter_message || "Hi there! Please sign in to chat with me.");
        setIsLoading(false);
      }
    }
  }, [companion, session]);

  // Update the handlePlayClick function
  const handlePlayClick = async (messageId, text) => {
    if (playingMessageId === messageId) {
      setPlayingMessageId(null);
      // TODO: Implement stop functionality for text-to-speech
    } else {
      setLoadingMessageId(messageId);
      try {
        // First, check if the message already has an audio_url
        const { data: messageData, error: messageError } = await supabase
          .from('chats')
          .select('audio_url')
          .eq('id', messageId)
          .single();

        if (messageError) throw messageError;

        let audioUrl = messageData.audio_url;

        if (!audioUrl) {
          // Check if the user has credits before generating speech
          if (userCredits <= 0) {
            openPricingModal();
            return;
          }

          // If no audio_url exists, generate new speech
          const { data, error } = await supabase.functions.invoke('generate-speech', {
            body: JSON.stringify({ text, messageId, companionId }),
          });

          if (error) throw error;

          audioUrl = data.audioUrl;
          
          // Fetch updated user credits after generating speech
          await fetchUserCredits();
        }

        setPlayingMessageId(messageId);
        const audio = new Audio(audioUrl);
        
        audio.onended = () => setPlayingMessageId(null);
        audio.play();
      } catch (error) {
        console.error('Error playing audio:', error);
      } finally {
        setLoadingMessageId(null);
      }
    }
  };

  // Add this new function to fetch user credits
  const fetchUserCredits = async () => {
    try {
      const { data, error } = await supabase
        .from('user_profiles')
        .select('credits')
        .eq('user_id', session.user.id)
        .single();

      if (error) throw error;
      
      if (data) {
        setUserCredits(data.credits);
      }
    } catch (error) {
      console.error('Error fetching user credits:', error.message);
    }
  };

  const renderMessages = () => {
    let currentDate = null;
    let messageGroups = [];
    let currentGroup = [];

    // Show starter message for non-logged in users
    if (!session || showStarterMessage) {
      const now = new Date();
      const starterMessageDate = formatDate(now);
      messageGroups.push({
        date: starterMessageDate,
        messages: [{
          id: 'starter-message',
          sender_type: 'companion',
          content: { text: starterMessage || 'Hi there! Please sign in to chat with me.' },
          created_at: now.toISOString(),
          status: 'sent' // Mark as sent to avoid showing typing animation
        }]
      });
      
      // Return early for non-logged in users
      if (!session) {
        return (
          <>
            {messageGroups.map(({ date, messages }) => (
              <div key={date}>
                <StickyDateHeader date={date} />
                {messages.map((msg) => (
                  <div 
                    key={msg.id} 
                    className="flex justify-start mb-1"
                  >
                    <img 
                      src={companion?.display_image} 
                      alt={companion?.name} 
                      className="w-8 h-8 rounded-full mr-2 flex-shrink-0 object-cover" 
                    />
                    <div className="flex flex-col items-start">
                      <div className="rounded-2xl px-3 py-2 max-w-xs lg:max-w-md bg-gray-800 text-gray-100 chat-bubble">
                        <p className="text-sm">{msg.content.text}</p>
                      </div>
                      <div className="flex items-center mt-1">
                        <span className="text-xs text-gray-400">
                          {formatTime(msg.created_at)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </>
        );
      }
    }

    chatMessages.forEach((msg, index) => {
      const messageDate = formatDate(msg.created_at);
      if (messageDate !== currentDate) {
        if (currentGroup.length > 0) {
          messageGroups.push({ date: currentDate, messages: currentGroup });
        }
        currentDate = messageDate;
        currentGroup = [];
      }
      currentGroup.push({ ...msg, index });
    });

    if (currentGroup.length > 0) {
      messageGroups.push({ date: currentDate, messages: currentGroup });
    }

    return (
      <>
        {messageGroups.map(({ date, messages }) => (
          <div key={date}>
            <StickyDateHeader date={date} />
            {messages.map((msg) => {
              const isHighlighted = searchResults[currentSearchIndex]?.index === msg.index;
              const isImageMessage = msg.content && typeof msg.content === 'object' && msg.content.image_url;
              const isCompletedMessage = msg.sender_type === 'companion' && msg.status !== 'typing' && !msg.isStarterMessage;
              const isTypingMessage = msg.sender_type === 'companion' && (msg.status === 'typing' || msg.isStarterMessage);
              const isUploadingImage = msg.content && typeof msg.content === 'object' && msg.content.text === 'Uploading image...';

              return (
                <div 
                  key={msg.id || msg.index} 
                  className={`flex ${msg.sender_type === 'user' ? 'justify-end' : 'justify-start'} mb-1 ${
                    isHighlighted ? 'highlighted-message' : ''
                  }`}
                  ref={isHighlighted ? scrollToMessage : null}
                >
                  {msg.sender_type === 'companion' && (
                    <img src={companion?.display_image} alt={companion?.name} className="w-8 h-8 rounded-full mr-2 flex-shrink-0 object-cover" />
                  )}
                  <div className={`flex flex-col ${msg.sender_type === 'user' ? 'items-end' : 'items-start'}`}>
                    {isUploadingImage ? (
                      <ShimmeringImagePlaceholder />
                    ) : (
                      <div className={`rounded-2xl ${
                        isImageMessage ? 'p-0 overflow-hidden' : 'px-3 py-2'
                      } max-w-xs lg:max-w-md ${
                        msg.sender_type === 'user' 
                          ? 'bg-blue-600 text-white' 
                          : 'bg-gray-800 text-gray-100'
                      } chat-bubble`}> {/* Added chat-bubble class here */}
                        {isTypingMessage ? (
                          <TypingAnimation />
                        ) : (
                          <>
                            {msg.content && typeof msg.content === 'object' && msg.content.text && (
                              <p 
                                className="text-sm"
                                dangerouslySetInnerHTML={{ 
                                  __html: formatMessage(msg.content.text, isHighlighted ? searchQuery : '') 
                                }}
                              />
                            )}
                            {msg.content && typeof msg.content === 'object' && msg.content.image_url && (
                              <div className="relative group">
                                <img 
                                  src={msg.content.image_url} 
                                  alt="AI generated image" 
                                  className="w-full h-auto rounded-lg cursor-pointer"
                                  onClick={() => handleImageClick(msg.content.image_url)}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "https://via.placeholder.com/300x200?text=Image+Load+Error";
                                  }}
                                />
                                <div className="absolute top-2 right-2 bg-black bg-opacity-50 rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                                  </svg>
                                </div>
                              </div>
                            )}
                            {msg.content && typeof msg.content === 'string' && (
                              <p 
                                className="text-sm"
                                dangerouslySetInnerHTML={{ 
                                  __html: formatMessage(msg.content, isHighlighted ? searchQuery : '') 
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <div className="flex items-center mt-1">
                      <span className="text-xs text-gray-400">
                        {formatTime(msg.created_at)}
                      </span>
                      {/* Comment out the PlayButton while preserving the code
                      {isCompletedMessage && !isImageMessage && (
                        <PlayButton
                          onClick={() => handlePlayClick(msg.id, msg.content.text)}
                          isPlaying={playingMessageId === msg.id}
                          isLoading={loadingMessageId === msg.id}
                        />
                      )} */}
                      {msg.sender_type === 'user' && (
                        <span className="ml-2">
                          {renderMessageStatus(msg)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </>
    );
  };

  const scrollToMessage = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  // Add this new useEffect hook to handle clicks outside the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Add this new function inside the Chat component
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  return (
    <div className="flex flex-col h-full bg-black text-gray-100">
      <header className="bg-black shadow-md">
        <div className="flex items-center justify-between p-4">
          <div className="flex-1"></div>
          <div className="flex flex-col items-center">
            <div className="w-12 h-12 sm:w-16 sm:h-16 mb-2 flex-shrink-0 flex items-center justify-center">
              <img 
                src={companion?.display_image} 
                alt={companion?.name} 
                className="w-full h-full rounded-full object-cover border-2 border-blue-400 cursor-pointer display-image" // Add display-image class
                onClick={() => handleImageClick(companion?.display_image)}
              />
            </div>
            <h2 className="text-lg sm:text-xl font-semibold text-gray-100 text-center">{companion?.name}</h2>
            <p className="text-xs sm:text-sm text-gray-400 text-center mt-1">{companion?.description}</p>
          </div>
          <div className="flex-1"></div>
        </div>
      </header>

      <div className="flex-1 overflow-hidden">
        <div className="h-full overflow-y-auto custom-scrollbar">
          <div className="chat-messages-container px-2 sm:px-8 md:px-16 lg:px-24 xl:px-32"> {/* Reduced padding for mobile */}
            {renderMessages()}
            <div ref={messagesEndRef} />
          </div>
        </div>
      </div>

      <div className="bg-black py-2 sm:py-4 w-full">
        <div className="px-2 sm:px-8 md:px-16 lg:px-24 xl:px-32"> {/* Reduced padding for mobile */}
          <div className="flex items-center space-x-2">
            <form onSubmit={handleSendMessage} className="flex-1">
              <div className="flex-1 bg-gray-900 rounded-full shadow-lg flex items-center h-12 sm:h-14">
                <textarea
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    e.target.style.height = 'auto';
                    e.target.style.height = Math.min(e.target.scrollHeight, 120) + 'px';
                  }}
                  onKeyDown={handleKeyDown}
                  placeholder={`Message ${companion?.name || 'AI'}...`}
                  className="flex-1 bg-transparent rounded-full py-2 sm:py-3 px-4 sm:px-6 focus:outline-none text-gray-100 text-sm sm:text-base resize-none overflow-y-auto min-h-[40px] max-h-[120px]"
                  disabled={isLoading}
                  rows="1"
                  style={{ lineHeight: '20px' }}
                />
                <button
                  type="submit"
                  className={`bg-blue-500 rounded-full p-2 m-1 hover:bg-blue-600 transition-colors ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                >
                  <IoSend className="w-4 h-4 sm:w-5 sm:h-5 text-white" />
                </button>
              </div>
            </form>
            
            {/* Menu button for mobile */}
            <div className="relative sm:hidden" ref={menuRef}>
              <button
                onClick={toggleMenu}
                className="p-2 text-gray-400 hover:text-blue-400 transition-colors bg-gray-900 rounded-full h-12 w-12 flex items-center justify-center"
              >
                <IoEllipsisVertical className="w-5 h-5" />
              </button>
              
              {isMenuOpen && (
                <div className="absolute bottom-full right-0 mb-2 w-48 bg-gray-900 rounded-lg shadow-lg overflow-hidden">
                  {/* Remove the search button from the mobile menu */}
                  <button
                    onClick={() => {
                      handleCallClick();
                      setIsMenuOpen(false);
                    }}
                    className="w-full flex items-center px-4 py-2 text-gray-500 cursor-not-allowed"
                  >
                    <IoCall className="w-5 h-5 mr-2" />
                    Voice Call (Coming Soon)
                  </button>
                  <button
                    onClick={() => {
                      handleClearChat();
                      setIsMenuOpen(false);
                    }}
                    className="w-full flex items-center px-4 py-2 text-gray-200 hover:bg-gray-800"
                  >
                    <IoRefresh className="w-5 h-5 mr-2" />
                    Reset Chat
                  </button>
                </div>
              )}
            </div>

            {/* Remove the Search dropdown for mobile */}

            {/* Buttons for larger screens */}
            <div className="hidden sm:flex items-center space-x-2">
              <div className="tooltip-container">
                <button
                  className="p-2 sm:p-3 text-gray-500 cursor-not-allowed bg-gray-900 rounded-full"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 sm:h-6 sm:w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                </button>
                <span className="tooltip">Voice Call (Coming Soon)</span>
              </div>
              
              <div className="w-px h-6 bg-gray-700 mx-2"></div> {/* Vertical separator */}
              
              <div className="flex space-x-2">
                <div ref={searchRef} className="relative">
                  <div className="tooltip-container">
                    <button
                      onClick={handleSearchClick}
                      className="p-2 sm:p-3 text-gray-400 hover:text-blue-400 transition-colors bg-gray-900 rounded-full"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 sm:h-6 sm:w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                      </svg>
                    </button>
                    <span className="tooltip">Search Messages</span>
                  </div>
                  {isSearching && (
                    <div className="absolute bottom-full right-0 mb-2 w-64 bg-gray-900 rounded-lg shadow-lg overflow-hidden">
                      <div className="flex items-center p-2">
                        <input
                          type="text"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onKeyDown={handleSearchKeyDown}
                          placeholder="Search messages..."
                          className="flex-1 bg-transparent focus:outline-none text-gray-100 text-sm"
                          autoFocus
                        />
                        <button onClick={handlePrevSearch} className="p-1 text-gray-400 hover:text-blue-400" disabled={searchResults.length === 0}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                          </svg>
                        </button>
                        <button onClick={handleNextSearch} className="p-1 text-gray-400 hover:text-blue-400" disabled={searchResults.length === 0}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                          </svg>
                        </button>
                      </div>
                      <div className="px-2 py-1 text-xs text-gray-400">
                        {searchResults.length > 0 ? `${currentSearchIndex + 1} of ${searchResults.length}` : 'No results'}
                      </div>
                    </div>
                  )}
                </div>
                <div className="tooltip-container">
                  <button
                    onClick={handleClearChat}
                    disabled={isClearingChat}
                    className="p-2 sm:p-3 text-gray-400 hover:text-blue-400 transition-colors disabled:opacity-50 bg-gray-900 rounded-full"
                  >
                    <IoRefresh className="h-5 w-5 sm:h-6 sm:w-6" />
                  </button>
                  <span className="tooltip">Reset Chat</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {error && (
        <p className="text-red-400 text-sm mt-2 px-4 absolute bottom-20 left-0 right-0 text-center">
          {error}
        </p>
      )}

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={confirmClearChat}
        title="Reset Chat"
        message="Are you sure you want to reset this chat? This action cannot be undone."
        isLoading={isClearingChat}
      />

      {showSuccessMessage && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-green-500 text-white px-6 py-4 rounded-lg shadow-lg">
            Chat cleared successfully!
          </div>
        </div>
      )}

      {selectedImage && (
        <ImageViewModal imageUrl={selectedImage} onClose={closeImageModal} />
      )}
    </div>
  );
}

export default Chat;

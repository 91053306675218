import React from 'react';
import { motion } from 'framer-motion';

function ProfileModal({ user, onClose }) {
  const avatarUrl = user.avatar_url || "https://vlknkypoydfchquzbfyw.supabase.co/storage/v1/object/public/assets/2.png";

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 modal-overlay" onClick={onClose}>
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 rounded-2xl w-full max-w-md mx-4 p-6 sm:p-8 relative shadow-2xl"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 sm:top-4 sm:right-4 text-gray-400 hover:text-gray-200 focus:outline-none transition-colors"
          aria-label="Close"
        >
          <svg className="h-5 w-5 sm:h-6 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-gray-100 text-center">Profile</h1>
        <div className="flex flex-col items-center">
          <img src={avatarUrl} alt="User Avatar" className="w-20 h-20 sm:w-24 sm:h-24 rounded-full mb-3 sm:mb-4" />
          <p className="text-base sm:text-lg text-gray-300 mb-2">{user.email}</p>
        </div>
      </motion.div>
    </div>
  );
}

export default ProfileModal;

import React, { useState } from 'react';
import { FaTimes, FaSpinner } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { supabase } from '../supabaseClient'; // Import supabase client
import AuthModal from './AuthModal'; // Import AuthModal component

function PricingModal({ onClose }) {
  const [amount, setAmount] = useState(1);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleAmountChange = (e) => {
    setAmount(Number(e.target.value));
  };

  const calculateImages = (dollars) => {
    const tokens = Math.floor(dollars * 25);
    return Math.floor(tokens / 3); // Each image costs 3 tokens
  };

  const presetBudgets = [1, 2, 5, 10, 20, 50];

  const handlePurchase = async () => {
    setIsLoading(true);
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (user) {
        // Fire Google Ads conversion event
        if (window.gtag) {
          window.gtag('event', 'conversion', {
            'send_to': 'AW-956690740/7DfKCJzhiuQZELTil8gD',
            'value': amount, // Using the actual amount they're trying to purchase
            'currency': 'USD'
          });
        }

        // Call the edge function to get payment URL with user email
        const { data, error } = await supabase.functions.invoke('get-payment-url', {
          body: { 
            amount: amount,
            email: user.email
          }
        });

        if (error) throw error;

        // Open the returned URL in a new tab
        window.open(data.url, '_blank');
      } else {
        // User is not logged in, show AuthModal
        setShowAuthModal(true);
      }
    } catch (error) {
      console.error('Error getting payment URL:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAuthModalClose = () => {
    setShowAuthModal(false);
    onClose(); // Close the entire modal when AuthModal is closed
  };

  if (showAuthModal) {
    return <AuthModal isLogin={true} onClose={handleAuthModalClose} />;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 p-4 sm:p-0"
      onClick={handleBackdropClick}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 p-6 sm:p-8 rounded-2xl w-full max-w-[95%] sm:max-w-md shadow-2xl relative overflow-hidden"
      >
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-400 hover:text-gray-200 transition-colors">
          <FaTimes size={24} />
        </button>

        <h2 className="text-2xl sm:text-3xl font-bold text-gray-100 mb-6">Buy Tokens</h2>

        <div className="mb-8">
          <div className="flex justify-between gap-1.5 sm:gap-2 mb-6">
            {presetBudgets.map((budget) => (
              <motion.button
                key={budget}
                onClick={() => setAmount(budget)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`px-2 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm font-medium transition-all duration-200 flex-1 max-w-[60px] sm:max-w-none ${
                  amount === budget
                    ? 'bg-gradient-to-r from-red-700 to-red-900 text-white shadow-lg' 
                    : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                }`}
              >
                ${budget}
              </motion.button>
            ))}
          </div>

          <div className="bg-gray-800 p-4 sm:p-6 rounded-xl shadow-inner">
            <input 
              type="range" 
              min="1" 
              max="100" 
              value={amount} 
              onChange={handleAmountChange}
              className="w-full h-2 bg-gray-600 rounded-full appearance-none cursor-pointer mb-4 relative"
              style={{
                WebkitAppearance: 'none',
                outline: 'none',
              }}
            />
            <style jsx>{`
              input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background: linear-gradient(to right, #ef4444, #991b1b);
                cursor: pointer;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
              }
              input[type='range']::-moz-range-thumb {
                width: 18px;
                height: 18px;
                border: none;
                border-radius: 50%;
                background: linear-gradient(to right, #ef4444, #991b1b);
                cursor: pointer;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
              }
            `}</style>
            <div className="flex justify-between items-center">
              <p className="text-3xl sm:text-4xl font-extrabold text-white">
                ${Math.floor(amount)}
              </p>
              <div className="flex items-center gap-1">
                <p className="text-2xl sm:text-3xl font-bold text-gray-100">{calculateImages(amount).toLocaleString()}</p>
                <p className="text-sm sm:text-base font-medium text-gray-400">images</p>
              </div>
            </div>
          </div>
        </div>

        <motion.button
          whileHover={{ scale: isLoading ? 1 : 1.02 }}
          whileTap={{ scale: isLoading ? 1 : 0.98 }}
          onClick={handlePurchase}
          disabled={isLoading}
          className={`w-full bg-gradient-to-r from-red-700 to-red-900 text-white py-3 sm:py-4 rounded-xl text-base sm:text-lg font-bold transition duration-300 shadow-lg flex items-center justify-center ${
            isLoading 
              ? 'opacity-75 cursor-not-allowed' 
              : 'hover:from-red-800 hover:to-red-950'
          }`}
        >
          {isLoading ? (
            <>
              <FaSpinner className="animate-spin mr-2" />
              Processing...
            </>
          ) : (
            `Buy ${Math.floor(amount * 25).toLocaleString()} Tokens`
          )}
        </motion.button>
      </motion.div>
    </motion.div>
  );
}

export default PricingModal;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { formatDistanceToNow, differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns';

const fontClasses = ['font-arima'];

const getFontClass = (index) => {
  return fontClasses[index % fontClasses.length];
};

const formatLastChatTime = (lastChatTime) => {
  const now = new Date();
  const chatTime = new Date(lastChatTime);
  const minutesAgo = differenceInMinutes(now, chatTime);
  const hoursAgo = differenceInHours(now, chatTime);
  const daysAgo = differenceInDays(now, chatTime);

  if (minutesAgo < 1) {
    return 'Just now';
  } else if (minutesAgo < 60) {
    return `${minutesAgo}m ago`;
  } else if (hoursAgo < 24) {
    return `${hoursAgo}h ago`;
  } else if (daysAgo === 1) {
    return 'Yesterday';
  } else if (daysAgo < 7) {
    return `${daysAgo}d ago`;
  } else {
    return formatDistanceToNow(chatTime, { addSuffix: true });
  }
};

function RecentChats({ session }) {
  const [recentChats, setRecentChats] = useState([]);

  useEffect(() => {
    if (session) {
      fetchRecentChats();
    }
  }, [session]);

  const fetchRecentChats = async () => {
    if (!session) return;

    const { data, error } = await supabase
      .from('chats')
      .select('companion_id, created_at')
      .eq('user_id', session.user.id)
      .eq('is_deleted', false)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching recent chats:', error);
    } else {
      const recentCompanionIds = [...new Set(data.map(chat => chat.companion_id))];
      const { data: recentCompanions, error: companionsError } = await supabase
        .from('companions')
        .select('*')
        .in('id', recentCompanionIds);

      if (companionsError) {
        console.error('Error fetching recent companions:', companionsError);
      } else {
        const enrichedCompanions = recentCompanions.map(companion => {
          const lastChat = data.find(chat => chat.companion_id === companion.id);
          return {
            ...companion,
            lastChatTime: lastChat ? lastChat.created_at : null,
          };
        });

        // Sort the enrichedCompanions array by lastChatTime
        const sortedCompanions = enrichedCompanions.sort((a, b) => {
          if (!a.lastChatTime) return 1;
          if (!b.lastChatTime) return -1;
          return new Date(b.lastChatTime) - new Date(a.lastChatTime);
        });

        setRecentChats(sortedCompanions);
      }
    }
  };

  return (
    <div className="main-content flex-1 bg-black text-white overflow-y-auto flex flex-col min-h-screen">
      <div className="max-w-7xl mx-auto w-full flex-grow px-0 sm:px-6 lg:px-8 pt-4 sm:pt-8">
        <h2 className="text-2xl sm:text-3xl font-bold mb-4 px-0 sm:px-0">Recent Chats</h2>
        {recentChats.length === 0 ? (
          <div className="px-0 sm:px-0">
            <p className="text-xl text-gray-400 mt-8">You haven't started any chats yet. Begin a conversation to see it here!</p>
            <Link to="/" className="inline-block mt-8 px-6 py-2 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-700 transition-colors duration-300">
              Start a Chat
            </Link>
          </div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-4 md:gap-5 lg:gap-6 px-0 sm:px-0">
            {recentChats.map((companion, index) => (
              <CompanionCard key={companion.id} companion={companion} index={index} />
            ))}
          </div>
        )}
      </div>
      <footer className="mt-auto pt-8 pb-4 text-center bg-black">
        <nav className="flex justify-center space-x-6">
          <Link to="/terms" target="_blank" className="text-gray-400 hover:text-white transition-colors duration-300">Terms</Link>
          <Link to="/privacy" target="_blank" className="text-gray-400 hover:text-white transition-colors duration-300">Privacy</Link>
        </nav>
      </footer>
    </div>
  );
}

const CompanionCard = ({ companion, index }) => (
  <Link to={`/chat/${companion.id}`} className="block">
    <div className="relative rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 group" style={{ paddingBottom: '150%' }}>
      <img 
        src={companion.display_image} 
        alt={companion.name} 
        className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent"></div>
      {companion.lastChatTime && (
        <div className="absolute top-2 right-2 flex items-center bg-black bg-opacity-50 rounded-full px-2 py-1">
          <div className="w-2 h-2 bg-green-400 rounded-full mr-2"></div>
          <p className="text-xs text-white">
            {formatLastChatTime(companion.lastChatTime)}
          </p>
        </div>
      )}
      <div className="absolute inset-x-0 bottom-0 p-4 text-center">
        <h2 className={`text-2xl sm:text-3xl md:text-4xl font-bold text-white ${getFontClass(index)}`}>
          {companion.name.toUpperCase()}
        </h2>
        <div className="w-4 h-1 bg-white mx-auto my-2"></div>
        <p className="text-xs sm:text-sm font-light text-gray-200 line-clamp-2 group-hover:line-clamp-none transition-all duration-300">
          {companion.description}
        </p>
      </div>
    </div>
  </Link>
);

export default RecentChats;

import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { format, formatDistanceToNow } from 'date-fns';
import ImageViewModal from './ImageViewModal';
import { Link } from 'react-router-dom';

// Updated PhotoCard component with onClick handler
const PhotoCard = ({ imageUrl, createdAt, onClick }) => {
  const formatDate = (date) => {
    const now = new Date();
    const diffInDays = Math.floor((now - new Date(date)) / (1000 * 60 * 60 * 24));

    if (diffInDays < 1) {
      return formatDistanceToNow(new Date(date), { addSuffix: true });
    } else if (diffInDays < 7) {
      return format(new Date(date), 'EEEE');
    } else {
      return format(new Date(date), 'MMM d, yyyy');
    }
  };

  const formattedDate = createdAt ? formatDate(createdAt) : 'Unknown date';

  return (
    <div 
      className="relative rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 group cursor-pointer"
      style={{ paddingBottom: '150%' }}
      onClick={() => onClick(imageUrl)}
    >
      <img 
        src={imageUrl} 
        alt="Generated image" 
        className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
      />
      <div 
        className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black to-transparent p-4"
      >
        <p className="text-white text-sm font-semibold">{formattedDate}</p>
      </div>
    </div>
  );
};

function PhotoLibrary({ session, openModal }) {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (session) {
      fetchImages();
    }
  }, [session]);

  const fetchImages = async () => {
    if (!session) return;

    const { data, error } = await supabase
      .from('chats')
      .select('content, created_at')
      .eq('user_id', session.user.id)
      .eq('sender_type', 'companion')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching images:', error);
    } else {
      const imageData = data
        .filter(message => 
          message.content && 
          typeof message.content === 'object' && 
          message.content.image_url
        )
        .map(message => ({
          imageUrl: message.content.image_url,
          createdAt: message.created_at
        }));
      setImages(imageData);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  if (!session) {
    return (
      <div className="flex items-center justify-center h-full">
        <button
          onClick={() => openModal(true)}
          className="bg-red-600 text-white px-6 py-2 rounded-lg text-lg font-semibold"
        >
          Sign in to view your Photo Library
        </button>
      </div>
    );
  }

  return (
    <div className="main-content flex-1 bg-black text-white overflow-y-auto flex flex-col min-h-screen">
      <div className="max-w-7xl mx-auto w-full flex-grow px-0 sm:px-6 lg:px-8 pt-4 sm:pt-8">
        <div className="mb-8">
          <div className="flex items-center justify-between mb-4 px-0 sm:px-0">
            <h2 className="text-2xl sm:text-3xl font-bold">Photo Library</h2>
          </div>
          {images.length === 0 ? (
            <div className="px-0 sm:px-0">
              <p className="text-xl text-gray-400">No images found in your chats.</p>
              <Link to="/" className="inline-block mt-8 px-6 py-2 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-700 transition-colors duration-300">
                Start a Chat
              </Link>
            </div>
          ) : (
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-4 md:gap-5 lg:gap-6 px-0 sm:px-0">
              {images.map((image, index) => (
                <PhotoCard 
                  key={index}
                  imageUrl={image.imageUrl} 
                  createdAt={image.createdAt}
                  onClick={handleImageClick}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <footer className="mt-auto pt-8 pb-4 text-center bg-black">
        <nav className="flex justify-center space-x-6">
          <Link to="/terms" target="_blank" className="text-gray-400 hover:text-white transition-colors duration-300">Terms</Link>
          <Link to="/privacy" target="_blank" className="text-gray-400 hover:text-white transition-colors duration-300">Privacy</Link>
        </nav>
      </footer>
      {selectedImage && (
        <ImageViewModal imageUrl={selectedImage} onClose={handleCloseModal} />
      )}
    </div>
  );
}

export default PhotoLibrary;

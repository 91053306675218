import React from 'react';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div className="terms-container p-8 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Banter Terms of Service</h1>
      
      <p className="mb-4">
        Welcome to Banter! These Terms of Service form an agreement between you and Banter, governing your access to our website (https://trybanter.com) and any related applications or platforms (collectively, the "Services"). Hereafter, Banter will be referred to as "Banter," "we," "us," or "our."
      </p>

      <p className="mb-4">
        By accessing or using the Services, you confirm that you accept and agree to these Terms. If you do not agree with these Terms, please discontinue use of the Services immediately.
      </p>

      <p className="mb-4">
        When we use the words "you" and "your" in these Terms, they refer to you as the individual user of the Services. If you are accessing the Services on behalf of an organization or entity, then "you" also includes that organization or entity, and you represent and warrant that (a) you have the authority to bind that entity to these Terms, and (b) you agree to these Terms on behalf of that entity.
      </p>

      <p className="mb-6 font-bold">
        IMPORTANT: THESE TERMS CONTAIN AN ARBITRATION CLAUSE AND CLASS ACTION WAIVER. By agreeing to these Terms, you agree to resolve any disputes through individual arbitration and waive your right to a judge, jury, or participation in a class action. You may opt out of arbitration as detailed below.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">1. Use of the Services</h2>
      
      <h3 className="text-xl font-semibold mt-4 mb-2">Your Registration Obligations</h3>
      <p className="mb-4">
        When registering to use the Services, you agree to provide accurate and current information. You must be at least 18 years old to use our Services.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">Member Account, Password, and Security</h3>
      <p className="mb-4">
        You are responsible for maintaining the confidentiality of your account credentials and for all activity under your account. Notify Banter immediately of any unauthorized account use or security breaches. We are not liable for any losses arising from your failure to comply with these security obligations.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">General Practices Regarding Use and Storage</h3>
      <p className="mb-4">
        You acknowledge that Banter may establish general practices regarding data storage and usage limits, such as maximum retention periods and storage allocations. We reserve the right to delete data or content that exceeds these limits or to terminate accounts that remain inactive for extended periods. Changes to these practices may occur at our sole discretion, with or without prior notice.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">Email Notifications</h3>
      <p className="mb-4">
        By providing your email, you agree to receive electronic notifications related to your account, updates, or other communications relevant to the Services.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">2. Conditions of Use</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">User Conduct</h3>
      <p className="mb-4">
        In addition to our Community Guidelines, which are incorporated into these Terms, you agree to the following standards of conduct while using the Services.
        You are responsible for all content you submit or post through the Services. This includes, but is not limited to, text, images, videos, sounds, graphics, and other data (collectively, “Content”). You agree not to submit any Content that:
        (i) infringes intellectual property or other proprietary rights; (ii) contains viruses, malware, or harmful code; (iii) poses a privacy or security risk to others; (iv) promotes unauthorized advertising, “spam,” or other solicitations; (v) is defamatory, false, threatening, or abusive; (vi) constitutes hate speech or promotes discrimination; (vii) is obscene or sexually explicit; (viii) encourages self-harm or violence; (ix) supports terrorism or criminal activity; (x) seeks or provides illegal goods or services; (xi) facilitates fully automated decision-making that affects individuals’ rights; (xii) gives unqualified medical, legal, or financial advice; (xiii) interferes with or disrupts the Services or other users’ access; (xiv) violates any person's rights of publicity by using their likeness or persona without permission; or (xv) otherwise restricts others from using and enjoying the Services.
      </p>
      <p className="mb-4">
        In addition, you agree not to:
        (i) violate any applicable laws or regulations; (ii) impersonate others or misrepresent your affiliation; (iii) collect personal information from users under 18; (iv) harvest contact information for unsolicited communications; (v) attempt to bypass security measures on the Services; or (vi) reverse engineer, disassemble, or attempt to access the Services’ source code.
      </p>
      <p className="mb-4">
        Should the Services support voice or audio features in the future, you agree not to submit voice recordings of others without consent or use voice features for “deepfake” impersonations or other harmful conduct.
      </p>
      <p className="mb-4">
        We reserve the right to investigate and take action for any violation of these Terms, which may include account suspension or termination, removal of content, and reporting to law enforcement.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">3. Intellectual Property Rights</h2>
      <h3 className="text-xl font-semibold mt-4 mb-2">Content You Submit</h3>
      <p className="mb-4">
        You retain ownership of all rights in any Content you submit. By submitting Content, you grant Banter a worldwide, royalty-free, sublicensable, perpetual license to use, display, and distribute your Content in connection with operating and improving the Services. We may also allow third parties to access and use your Content as part of our Services.
      </p>
      <p className="mb-4">
        While we may review Content, we are not obligated to do so. We reserve the right to screen, edit, or remove Content for any reason, including to ensure compliance with these Terms or applicable laws.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">Characters & Generations</h3>
      <p className="mb-4">
        If you create an AI character ("Character") or elicit content (“Generations”) from the Services, you retain ownership of the Character and Generations created by you. By using the Services, you grant Banter a license to use, display, and distribute these for promoting and improving the Services.
      </p>
      <h3 className="text-xl font-semibold mt-4 mb-2">Services Content, Software, and Trademarks</h3>
      <p className="mb-4">
        The Services and their content, including software and trademarks, are protected by intellectual property laws. You agree not to engage in unauthorized use of any Banter intellectual property.
      </p>
      <p className="mb-4">
        The Banter name and logo are trademarks of Banter. Other trademarks appearing on the Services belong to their respective owners. These Terms do not grant any license to use these trademarks without permission.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">4. Third-Party Material</h2>
      <p className="mb-4">
        Banter assumes no responsibility for third-party content, applications, or resources accessible through the Services. You acknowledge that third-party content may be unpredictable or offensive and agree to use it at your own risk.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">5. Indemnity and Release</h2>
      <p className="mb-4">
        You agree to indemnify and hold harmless Banter and its affiliates, officers, and employees from any claims, damages, or losses arising from your use of the Services. This includes all legal fees and expenses associated with any violation of these Terms or applicable laws.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">6. Disclaimer of Warranty</h2>
      <p className="mb-4">
        Your use of the Services is at your own risk. The Services are provided "AS IS" and "AS AVAILABLE," without any warranties of any kind. Banter disclaims all warranties, express or implied, regarding the Services' reliability, security, and accuracy.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">7. Limitation of Liability</h2>
      <p className="mb-4">
        To the fullest extent permitted by law, Banter is not liable for any indirect, incidental, or consequential damages resulting from your use of the Services. Our liability will not exceed the amount you paid, if any, to access the Services.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">8. Dispute Resolution By Binding Arbitration</h2>
      <p className="mb-4">
        By using the Services, you agree to resolve disputes through individual arbitration rather than court litigation. This includes waiving rights to a jury trial and class action. The Singapore International Arbitration Centre (SIAC) will administer any arbitration under its rules. You may opt out of this agreement to arbitrate within 30 days of first accepting these Terms.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">9. Termination</h2>
      <p className="mb-4">
        Banter reserves the right to suspend or terminate your account for any reason, including inactivity or violation of these Terms. Upon termination, Banter may delete your account and any associated data.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">10. General</h2>
      <ul className="list-disc pl-6 mb-4">
        <li><strong>Entire Agreement:</strong> These Terms represent the complete agreement between you and Banter regarding the Services.</li>
        <li><strong>Governing Law:</strong> These Terms are governed by Singapore law.</li>
        <li><strong>Severability:</strong> If any part of these Terms is found invalid, the rest will remain in full effect.</li>
        <li><strong>No Waiver:</strong> Banter’s failure to enforce any part of these Terms does not constitute a waiver.</li>
        <li><strong>Changes to these Terms:</strong> We may update these Terms at any time, with changes effective 14 days after posting. Continued use of the Services indicates acceptance of the updated Terms.</li>
      </ul>

      <footer className="mt-auto pt-8 pb-4 text-center bg-black">
        <nav className="flex justify-center space-x-6">
          <Link to="/terms" target="_blank" className="text-gray-400 hover:text-white transition-colors duration-300">Terms</Link>
          <Link to="/privacy" target="_blank" className="text-gray-400 hover:text-white transition-colors duration-300">Privacy</Link>
        </nav>
      </footer>
    </div>
  );
};

export default Terms;

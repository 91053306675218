import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './utils/posthog';
import posthog from 'posthog-js'
import { supabase } from './supabaseClient'

// Initialize PostHog and check for authenticated user
const initializeAnalytics = async () => {
  const { data: { user } } = await supabase.auth.getUser()
  if (user) {
    posthog.identify(user.id, {
      email: user.email,
      name: user.user_metadata?.full_name
    })
  }
}

initializeAnalytics()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

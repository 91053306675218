import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaCaretDown, FaSearch, FaCoins, FaBars, FaTimes } from 'react-icons/fa';
import ProfileModal from './ProfileModal'; // Import the new ProfileModal component
import { createClient } from '@supabase/supabase-js';

// Create a new Supabase client
const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

function TopBar({ session, openModal, logout, setGlobalSearchTerm, openPricingModal }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false); // New state for ProfileModal
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);
  const navigate = useNavigate();
  const [userCredits, setUserCredits] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);

  useEffect(() => {
    let subscription;

    async function setupSubscription() {
      if (session && session.user) {
        // Initial fetch of user credits
        await fetchUserCredits(session.user.id);

        // Set up real-time subscription
        subscription = supabase
          .channel('custom-all-channel')
          .on(
            'postgres_changes',
            {
              event: 'UPDATE',
              schema: 'public',
              table: 'user_profiles',
              filter: `user_id=eq.${session.user.id}`
            },
            (payload) => {
              if (payload.new) {
                setUserCredits(payload.new.credits);
              }
            }
          )
          .subscribe();
      }
    }

    setupSubscription();

    // Cleanup function
    return () => {
      if (subscription) {
        supabase.removeChannel(subscription);
      }
    };
  }, [session]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setGlobalSearchTerm(newSearchTerm);
    navigate('/');
  };

  const handleRecentChatsClick = (e) => {
    if (!session) {
      e.preventDefault();
      openModal(true);
    }
  };

  const handlePhotoLibraryClick = (e) => {
    if (!session) {
      e.preventDefault();
      openModal(true);
    }
  };

  const openProfileModal = () => {
    setIsProfileModalOpen(true);
    setDropdownOpen(false);
  };

  const closeProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const fetchUserCredits = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('user_profiles')
        .select('credits')
        .eq('user_id', userId)
        .single();

      if (error) throw error;
      
      if (data) {
        setUserCredits(data.credits);
      }
    } catch (error) {
      console.error('Error fetching user credits:', error.message);
    }
  };

  const handleCreditsClick = () => {
    openPricingModal();
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleMobileSearch = () => {
    setIsMobileSearchOpen(!isMobileSearchOpen);
    if (!isMobileSearchOpen) {
      setTimeout(() => {
        document.getElementById('mobileSearchInput').focus();
      }, 100);
    }
  };

  return (
    <nav className="bg-black text-white py-4 px-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-6">
          <Link to="/" className="font-bold text-2xl flex items-center">
            <img
              src="https://vlknkypoydfchquzbfyw.supabase.co/storage/v1/object/public/assets/Banter-logo-large.png"
              alt="BANTER"
              className="h-8 mr-2"
            />
          </Link>
          
          {/* Desktop menu - on the left */}
          <div className="hidden md:flex items-center space-x-6">
            <Link to="/" className="hover:text-gray-300">Home</Link>
            <Link to="/recent-chats" className="hover:text-gray-300" onClick={handleRecentChatsClick}>Recent Chats</Link>
            <Link to="/photo-library" className="hover:text-gray-300" onClick={handlePhotoLibraryClick}>Photo Library</Link>
            <button onClick={openPricingModal} className="hover:text-gray-300">Pricing</button>
          </div>
        </div>
        
        <div className="flex items-center space-x-4">
          {/* Search bar - hidden on mobile */}
          <div 
            className="relative hidden sm:block" 
            ref={searchRef}
            onMouseEnter={() => setIsSearchExpanded(true)}
            onMouseLeave={() => setIsSearchExpanded(false)}
          >
            {isSearchExpanded ? (
              <input
                type="text"
                placeholder="Names, Interests, Genres"
                value={searchTerm}
                onChange={handleSearch}
                className="bg-gray-800 text-white px-2 py-1 pr-8 rounded transition-all duration-300 w-80"
                autoFocus
              />
            ) : (
              <FaSearch className="text-gray-400 cursor-pointer" />
            )}
            {isSearchExpanded && (
              <FaSearch className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
            )}
          </div>

          {/* Credits display - visible on all screen sizes */}
          {session && (
            <div 
              className="flex items-center space-x-2 bg-gray-800 rounded-full px-3 py-1 border border-gray-600 cursor-pointer hover:bg-gray-700 transition-colors duration-200"
              onClick={handleCreditsClick}
            >
              <FaCoins className="text-yellow-400" />
              <span className="font-semibold text-sm">{userCredits}</span>
            </div>
          )}
          
          {/* User menu - hidden on mobile */}
          <div className="hidden sm:flex items-center space-x-4">
            <button className="hover:text-gray-300">
              <i className="fas fa-bell"></i>
            </button>
            {session ? (
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={toggleDropdown}
                  className="flex items-center space-x-1 focus:outline-none"
                >
                  {session.user.avatar_url ? (
                    <img
                      src={session.user.avatar_url}
                      alt="User Avatar"
                      className="w-8 h-8 rounded-full"
                    />
                  ) : (
                    <img
                      src="https://vlknkypoydfchquzbfyw.supabase.co/storage/v1/object/public/assets/2.png"
                      alt="Default Avatar"
                      className="w-8 h-8"
                    />
                  )}
                  <FaCaretDown className={`transition-transform duration-200 ${dropdownOpen ? 'rotate-180' : ''}`} />
                </button>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-gray-900 rounded-md shadow-lg py-1 z-10 border border-gray-700">
                    <button
                      onClick={openProfileModal}
                      className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-800 transition-colors duration-200"
                    >
                      Profile
                    </button>
                    <button
                      onClick={() => {
                        logout();
                        setDropdownOpen(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-800 transition-colors duration-200"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button 
                className="bg-red-600 text-white px-4 py-1 rounded"
                onClick={() => openModal(true)}
              >
                Sign In
              </button>
            )}
          </div>
          
          {/* Mobile search icon */}
          <button
            className="md:hidden text-white focus:outline-none"
            onClick={toggleMobileSearch}
          >
            <FaSearch className="h-5 w-5" />
          </button>

          {/* Mobile menu button */}
          <button
            className="md:hidden text-white focus:outline-none"
            onClick={toggleMobileMenu}
          >
            <FaBars className="h-6 w-6" />
          </button>
        </div>
      </div>
      
      {/* Mobile search bar */}
      {isMobileSearchOpen && (
        <div className="md:hidden mt-4 relative">
          <input
            id="mobileSearchInput"
            type="text"
            placeholder="Search names, interests, genres..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full bg-gray-800 text-white px-4 py-2 pr-10 rounded"
          />
          <button
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400"
            onClick={toggleMobileSearch}
          >
            <FaTimes className="h-5 w-5" />
          </button>
        </div>
      )}

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden mt-4">
          <Link to="/" className="block py-2 hover:text-gray-300">Home</Link>
          <Link to="/recent-chats" className="block py-2 hover:text-gray-300" onClick={handleRecentChatsClick}>Recent Chats</Link>
          <Link to="/photo-library" className="block py-2 hover:text-gray-300" onClick={handlePhotoLibraryClick}>Photo Library</Link>
          <button onClick={openPricingModal} className="block w-full text-left py-2 hover:text-gray-300">Pricing</button>
          {session ? (
            <>
              <button
                onClick={openProfileModal}
                className="block w-full text-left py-2 hover:text-gray-300"
              >
                Profile
              </button>
              <button
                onClick={() => {
                  logout();
                  setIsMobileMenuOpen(false);
                }}
                className="block w-full text-left py-2 hover:text-gray-300"
              >
                Logout
              </button>
            </>
          ) : (
            <button 
              className="block w-full text-left py-2 hover:text-gray-300"
              onClick={() => {
                openModal(true);
                setIsMobileMenuOpen(false);
              }}
            >
              Sign In
            </button>
          )}
        </div>
      )}
      
      {isProfileModalOpen && (
        <ProfileModal user={session.user} onClose={closeProfileModal} />
      )}
    </nav>
  );
}

export default TopBar;

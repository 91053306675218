import React from 'react';

const Privacy = () => {
  return (
    <div className="privacy-container p-8 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Banter Privacy Policy</h1>
      
      <p className="mb-4">
        This Privacy Policy (the “Privacy Policy” or “Policy”) explains how Banter (“Banter,” “we,” “our,” or “us”) collects, uses, and discloses information about you.
      </p>

      <p className="mb-4">
        This Policy applies to all websites, mobile applications, and other electronic or digital services provided by Banter that link to this Policy, as well as to any offline services (collectively, the “Services”).
      </p>

      <p className="mb-4">
        By using the Services, you agree to this Policy. If you do not agree with any part of it, please do not access or use the Services.
      </p>

      <p className="mb-4">
        Undefined capitalized terms in this Policy have the same meanings as defined in the Banter Terms of Service.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">1. Information We Collect</h2>
      <p className="mb-4">
        When you use our Services, we collect various types of information. This may include information you provide directly, data we automatically collect through your interactions with the Services, and data from other sources. Certain information is essential for providing the Services; if you do not provide it, you may not be able to access some features.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">Information You Provide Directly</h3>
      <p className="mb-4">
        You may provide information directly to us, for instance, when you create an account, interact with us, or use specific features of the Services. Information you may share with us includes:
        <ul className="list-disc pl-5">
          <li>Email address</li>
          <li>Username</li>
          <li>Payment information (via our payment processing providers)</li>
          <li>User Content (such as chat interactions, images, and Characters shared on the Services)</li>
          <li>Contents of any communications you send to us</li>
        </ul>
        While you are not required to provide all information, certain features may be unavailable if you choose not to share specific data.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">Information We Collect Automatically</h3>
      <p className="mb-4">
        We, along with third-party vendors, may use cookies, web beacons, and other tracking technologies to collect information about the devices you use to access the Services. This information may include:
        <ul className="list-disc pl-5">
          <li>Browser type and device information</li>
          <li>Operating system and Internet Service Provider (ISP)</li>
          <li>Domain names and access times</li>
          <li>IP address and device identifiers (e.g., IDFA or Android ID)</li>
          <li>Service version and device type</li>
          <li>Location information (inferred from IP address)</li>
        </ul>
        We may also analyze usage patterns to understand the popularity of certain features and improve the Services. You can control some of this data collection through browser settings to limit cookies, though disabling cookies may limit your access to some features. Learn more about cookies at <a href="https://www.allaboutcookies.org">https://www.allaboutcookies.org</a>.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">Information From Other Sources</h3>
      <p className="mb-4">
        We may collect information from third-party sources, such as when a user refers you to us or when you interact with us through social media platforms like Facebook or Instagram. When you connect a third-party account to Banter, such as Google or Facebook, we receive data from that account based on your permissions, which you can adjust directly on the third-party platform.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">2. How We Use the Information We Collect</h2>
      <p className="mb-4">
        The information we collect is used to:
        <ul className="list-disc pl-5">
          <li>Provide and administer the Services</li>
          <li>Customize and personalize your experience with the Services</li>
          <li>Communicate with you, including regarding changes to the Services or Policy updates</li>
          <li>Improve and develop new features, Services, and programs</li>
          <li>Detect and prevent misuse, fraud, and security incidents</li>
          <li>Comply with legal obligations and enforce our Terms of Service</li>
          <li>Combine with other information for research, analysis, and marketing purposes as de-identified or aggregated data</li>
        </ul>
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">3. When We Disclose the Information We Collect</h2>
      <p className="mb-4">
        We may share your information in the following circumstances:
        <ul className="list-disc pl-5">
          <li><strong>Affiliates:</strong> We may share information with affiliates that are controlled by or under common control with Banter. These affiliates may use your information in ways consistent with this Policy.</li>
          <li><strong>Vendors:</strong> We may share data with vendors and service providers who assist in delivering the Services, such as payment processors, analytics providers, and customer service support.</li>
          <li><strong>Legal Compliance and Protection:</strong> We may share information to comply with legal obligations, enforce our rights, or protect Banter and others' rights, safety, or property.</li>
          <li><strong>Advertising and Analytics:</strong> If we engage in future advertising partnerships, we may share information with advertisers and analytics partners. These parties may use cookies and similar tracking to analyze usage and deliver tailored ads. For more information on managing your preferences, see the “Online Analytics and Tailored Advertising” section below.</li>
          <li><strong>Business Transfers:</strong> If Banter undergoes a merger, sale, financing, or acquisition, we may transfer information to the acquiring entity.</li>
          <li><strong>User Content:</strong> Some content, like Characters or chats in group settings, may be accessible to other users if designated as public or shared in group interactions.</li>
          <li><strong>With Your Consent or Direction:</strong> We may disclose information to nonaffiliated third parties based on your instructions, such as if you choose to refer a friend.</li>
        </ul>
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">4. Online Analytics and Tailored Advertising</h2>
      <p className="mb-4">
        <strong>Analytics</strong><br />
        We may use analytics tools like Google Analytics to understand how you use the Services. Google Analytics may collect data such as your web traffic and activity. You can opt out by installing the Google Analytics Opt-Out Browser Add-on.
      </p>
      <p className="mb-4">
        <strong>Tailored Advertising</strong><br />
        We may work with third-party networks for tailored advertising in the future. These networks may use cookies to gather information about your browsing behavior to serve targeted ads based on your interests. To learn more about tailored ads or to opt out, visit the Network Advertising Initiative’s Consumer Opt-Out Link, Digital Advertising Alliance’s Consumer Opt-Out Link, or the European Interactive Digital Advertising Alliance.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">5. Your Choices</h2>
      <p className="mb-4">
        We provide various options for managing your data:
        <ul className="list-disc pl-5">
          <li><strong>Profile Information:</strong> You may update or delete information through your account settings.</li>
          <li><strong>Marketing Communications:</strong> You can opt out of marketing emails by following unsubscribe instructions within emails. However, you will still receive necessary administrative communications.</li>
          <li><strong>Cookies and Analytics:</strong> You can manage certain tracking tools through your browser and opt-out programs as outlined in this Policy.</li>
          <li><strong>Rights to Information:</strong> Depending on your jurisdiction, you may request access, correction, or deletion of your personal information. Contact us to exercise these rights as permitted by applicable law.</li>
        </ul>
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">6. Regional Privacy Disclosures</h2>
      <p className="mb-4">
        <strong>European Economic Area and United Kingdom</strong><br />
        For residents of the European Economic Area and the United Kingdom, Banter serves as the “data controller” for your personal data. Our legal basis for using, sharing, and disclosing personal data includes:
        <ul className="list-disc pl-5">
          <li><strong>Contractual Commitments:</strong> Processing data to fulfill our agreements with you.</li>
          <li><strong>Consent:</strong> Where required, we process data based on your consent.</li>
          <li><strong>Legitimate Interests:</strong> We process data to support business interests not overridden by your rights, like customer support and fraud prevention.</li>
          <li><strong>Legal Compliance:</strong> We process data as needed to comply with legal obligations.</li>
        </ul>
        You may also have the right to file a complaint with your local data protection authority.
      </p>
      <p className="mb-4">
        <strong>Residents of Other Jurisdictions</strong><br />
        Depending on your jurisdiction, such as California or Nevada, additional rights may be available. Please contact us for specific information or to exercise your rights.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">7. Children’s Privacy</h2>
      <p className="mb-4">
        The Services are not intended for use by anyone under 18. If we become aware of personal information collected from a child under 18, we will delete it in accordance with applicable law.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">8. Security</h2>
      <p className="mb-4">
        Banter implements technical and organizational measures to protect information against unauthorized access or loss. However, no security measures are completely infallible, and we cannot guarantee absolute security.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">9. Data Retention</h2>
      <p className="mb-4">
        We retain your information only as long as necessary to fulfill the purposes outlined in this Policy or as required by law. After that period, we may delete or anonymize the data in accordance with applicable laws.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">10. Consent to Transfer</h2>
      <p className="mb-4">
        Banter is based in Singapore, and your information may be processed in Singapore or other locations where data protection laws may differ from your jurisdiction. By using the Services, you consent to this data transfer.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">11. Links to Third-Party Websites</h2>
      <p className="mb-4">
        The Services may link to third-party websites or services. We are not responsible for the privacy practices or content of these sites. We encourage you to review third-party privacy policies before providing any personal information.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">12. Changes to this Policy</h2>
      <p className="mb-4">
        We may update this Policy to reflect legal changes or changes in our information practices. We will indicate the date of the last update at the top of this Policy. For material changes, we will provide notice as required by law.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-4">13. Contact Us</h2>
      <p className="mb-4">
        If you have questions about this Policy or our data practices, please contact us at support@trybanter.com.
      </p>
    </div>
  );
};

export default Privacy;

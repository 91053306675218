import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import RecentChats from './components/RecentChats';
import Chat from './components/Chat';
import { supabase } from './supabaseClient';
import AuthModal from './components/AuthModal';
import TopBar from './components/TopBar';
import PhotoLibrary from './components/PhotoLibrary';
import PricingModal from './components/PricingModal';
import CategoryPage from './components/CategoryPage';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import posthog from 'posthog-js';

function App() {
  const [session, setSession] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [globalSearchTerm, setGlobalSearchTerm] = useState('');
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [companions, setCompanions] = useState([]);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' && session?.user) {
        posthog.identify(session.user.id, {
          email: session.user.email,
          name: session.user.user_metadata?.full_name
        })
      } else if (event === 'SIGNED_OUT') {
        posthog.reset() // Clear user identification when they sign out
      }
    })
  }, [])

  const openModal = (isLoginMode) => {
    setIsLogin(isLoginMode);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const logout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setSession(null);
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  const openPricingModal = () => {
    setIsPricingModalOpen(true);
  };

  const closePricingModal = () => {
    setIsPricingModalOpen(false);
  };

  useEffect(() => {
    fetchCompanions();
  }, []);

  const fetchCompanions = async () => {
    const { data, error } = await supabase
      .from('companions')
      .select('id, name, description, display_image, categories, is_public');

    if (error) {
      console.error('Error fetching companions:', error);
    } else {
      setCompanions(data);
    }
  };

  return (
    <Router>
      <div className="flex flex-col h-screen bg-black text-white">
        <TopBar 
          session={session} 
          openModal={openModal} 
          logout={logout} 
          setGlobalSearchTerm={setGlobalSearchTerm}
          openPricingModal={openPricingModal}
        />
        <div className="flex flex-1 overflow-hidden">
          <div className="flex-1 overflow-auto custom-scrollbar">
            <Routes>
              <Route 
                path="/" 
                element={
                  <MainContent 
                    session={session} 
                    openModal={openModal} 
                    isSidebarCollapsed={isSidebarCollapsed}
                    globalSearchTerm={globalSearchTerm}
                  />
                } 
              />
              <Route 
                path="/recent-chats" 
                element={
                  <RecentChats 
                    session={session} 
                  />
                } 
              />
              <Route 
                path="/chat/:companionId" 
                element={
                  <Chat 
                    session={session} 
                    openModal={openModal} 
                    isSidebarCollapsed={isSidebarCollapsed}
                    openPricingModal={openPricingModal}
                  />
                } 
              />
              <Route 
                path="/photo-library" 
                element={
                  <PhotoLibrary 
                    session={session} 
                    openModal={openModal} 
                    isSidebarCollapsed={isSidebarCollapsed}
                  />
                } 
              />
              <Route 
                path="/category/:category" 
                element={<CategoryPage companions={companions} />} 
              />
              <Route 
                path="/terms" 
                element={<Terms />} 
              />
              <Route 
                path="/privacy" 
                element={<Privacy />} 
              />
            </Routes>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <AuthModal isLogin={isLogin} onClose={closeModal} />
      )}
      {isPricingModalOpen && (
        <PricingModal onClose={closePricingModal} />
      )}
    </Router>
  );
}

export default App;

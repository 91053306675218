import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { CompanionCard } from './MainContent'; // Import the CompanionCard component

function CategoryPage({ companions }) {
  const { category } = useParams();
  const decodedCategory = decodeURIComponent(category);

  const filteredCompanions = companions.filter(companion =>
    companion.categories.includes(decodedCategory.charAt(0).toUpperCase() + decodedCategory.slice(1))
  );

  return (
    <div className="category-page flex-1 bg-black text-white overflow-y-auto flex flex-col min-h-screen">
      <div className="max-w-7xl mx-auto w-full flex-grow px-0 sm:px-6 lg:px-8 pt-8 sm:pt-16">
        {filteredCompanions.length > 0 && (
          <div className="mb-8 px-4 sm:px-0">
            <h1 className="text-2xl sm:text-3xl font-bold mb-4 capitalize">
              {decodedCategory}
            </h1>
          </div>
        )}
        {filteredCompanions.length > 0 ? (
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-4 md:gap-5 lg:gap-6 px-4 sm:px-0">
            {filteredCompanions.map((companion, index) => (
              <CompanionCard key={companion.id} companion={companion} index={index} />
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center h-[calc(100vh-200px)] px-4 sm:px-0">
            <div className="text-center">
              <h2 className="text-xl sm:text-2xl font-semibold mb-4">No companions found in this category</h2>
              <p className="text-gray-400 mb-8">Try exploring other categories or return to the home page.</p>
              <Link 
                to="/" 
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Return to Home
              </Link>
            </div>
          </div>
        )}
      </div>

      <footer className="mt-auto pt-8 pb-4 text-center bg-black">
        <nav className="flex justify-center space-x-6">
          <Link to="/terms" target="_blank" className="text-gray-400 hover:text-white transition-colors duration-300">Terms</Link>
          <Link to="/privacy" target="_blank" className="text-gray-400 hover:text-white transition-colors duration-300">Privacy</Link>
        </nav>
      </footer>
    </div>
  );
}

export default CategoryPage;

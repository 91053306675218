import React from 'react';

function ConfirmationModal({ isOpen, onClose, onConfirm, title, message, isLoading }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center">
      <div className="relative bg-gray-800 rounded-lg max-w-sm w-full mx-4 shadow-lg">
        <div className="p-6">
          <h3 className="text-lg font-medium text-gray-100 mb-2">
            {title}
          </h3>
          <p className="text-sm text-gray-300 mb-4">
            {message}
          </p>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-gray-300 hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="px-4 py-2 text-sm font-medium text-blue-400 hover:text-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={onConfirm}
              disabled={isLoading}
            >
              {isLoading ? 'Resetting...' : 'Reset'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;

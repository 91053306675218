import React, { useState, useCallback, useEffect } from 'react';
import { supabase } from '../supabaseClient'; // Make sure to import the Supabase client

function AuthModal({ isLogin: initialIsLogin, onClose }) {
  const [isLogin, setIsLogin] = useState(initialIsLogin);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);

  const handleClose = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  const handleAuth = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      let result;
      if (isResetPassword) {
        result = await supabase.auth.resetPasswordForEmail(email);
        setMessage('Password reset email sent. Check your inbox.');
      } else if (isLogin) {
        result = await supabase.auth.signInWithPassword({ email, password });
        if (!result.error) {
          setMessage('Login successful! Reloading...');
          setTimeout(() => {
            handleClose();
            window.location.reload();
          }, 1000);
        }
      } else {
        result = await supabase.auth.signUp({ email, password });
        setMessage('Registration successful! Please check your email to confirm your account.');
      }

      const { error } = result;
      if (error) throw error;
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    setMessage('');

    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      });

      if (error) throw error;

      // The page will be redirected to Google's authentication page.
      // After successful authentication, the user will be redirected back to your app.
    } catch (error) {
      setMessage(error.message);
      setLoading(false);
    }
  };

  const handleDiscordSignIn = async () => {
    setLoading(true);
    setMessage('');

    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'discord',
      });

      if (error) throw error;

      // The page will be redirected to Discord's authentication page
      // After successful authentication, the user will be redirected back to your app
    } catch (error) {
      setMessage(error.message);
      setLoading(false);
    }
  };

  const toggleAuthMode = () => {
    setIsLogin(!isLogin);
    setIsResetPassword(false);
    setMessage('');
  };

  const toggleResetPassword = () => {
    setIsResetPassword(!isResetPassword);
    setMessage('');
  };

  const handleOutsideClick = useCallback((e) => {
    if (e.target.classList.contains('modal-overlay')) {
      handleClose();
    }
  }, [handleClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 modal-overlay">
      <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 rounded-2xl w-full max-w-md mx-4 sm:mx-auto p-6 sm:p-8 relative shadow-2xl">
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 sm:top-4 sm:right-4 text-gray-400 hover:text-gray-200 focus:outline-none transition-colors"
          aria-label="Close"
        >
          <svg className="h-5 w-5 sm:h-6 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h1 className="text-2xl sm:text-3xl font-bold mb-2 text-gray-100 text-center">
          {isResetPassword ? 'Reset Password' : 'Welcome'}
        </h1>
        <p className="text-xs sm:text-sm text-gray-400 mb-4 sm:mb-6 text-center">
          {isResetPassword ? 'Enter your email' : 'Sign in or sign up to continue'}
        </p>
        
        {!isResetPassword && (
          <div className="space-y-3 sm:space-y-4 mb-4 sm:mb-6">
            <button
              onClick={handleGoogleSignIn}
              className="w-full py-2 px-3 sm:px-4 bg-gray-700 text-gray-200 text-sm sm:text-base font-semibold rounded-xl transition duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-600 flex items-center justify-center"
              disabled={loading}
            >
              <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
              Continue with Google
            </button>

            <button
              onClick={handleDiscordSignIn}
              className="w-full py-2 px-3 sm:px-4 bg-[#5865F2] text-white text-sm sm:text-base font-semibold rounded-xl transition duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5865F2] hover:bg-[#4752C4] flex items-center justify-center"
              disabled={loading}
            >
              <svg className="w-4 h-4 sm:w-5 sm:h-5 mr-2" viewBox="0 -28.5 256 256" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                <path d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z" fill="currentColor" fillRule="nonzero">
                </path>
              </svg>
              Continue with Discord
            </button>
          </div>
        )}

        {!isResetPassword && (
          <div className="relative mb-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-700"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-gray-800 text-gray-400">Or continue with</span>
            </div>
          </div>
        )}

        <form onSubmit={handleAuth} className="space-y-3 sm:space-y-4">
          <div>
            <label htmlFor="email" className="block text-xs sm:text-sm font-medium text-gray-300 mb-1">Email</label>
            <input
              id="email"
              type="email"
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-1.5 sm:py-2 bg-gray-700 border border-gray-600 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-200 text-gray-100 text-sm sm:text-base"
              required
            />
          </div>
          {!isResetPassword && (
            <div>
              <label htmlFor="password" className="block text-xs sm:text-sm font-medium text-gray-300 mb-1">Password</label>
              <input
                id="password"
                type="password"
                placeholder="••••••••"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-1.5 sm:py-2 bg-gray-700 border border-gray-600 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-200 text-gray-100 text-sm sm:text-base"
                required
              />
            </div>
          )}
          <button
            type="submit"
            className="w-full py-1.5 sm:py-2 px-4 bg-gradient-to-r from-red-700 to-red-900 hover:from-red-800 hover:to-red-950 text-white font-semibold rounded-xl transition duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed text-sm sm:text-base"
            disabled={loading}
          >
            {loading ? 'Processing...' : (isResetPassword ? 'Send Reset Link' : (isLogin ? 'Log In' : 'Sign Up'))}
          </button>
        </form>

        <div className="mt-3 sm:mt-4 text-center">
          {isLogin && !isResetPassword && (
            <button
              onClick={toggleResetPassword}
              className="text-xs sm:text-sm text-red-400 hover:text-red-300 focus:outline-none transition duration-200"
            >
              Forgot Password?
            </button>
          )}
          {isResetPassword && (
            <button
              onClick={toggleResetPassword}
              className="text-xs sm:text-sm text-red-400 hover:text-red-300 focus:outline-none transition duration-200"
            >
              Back to Login
            </button>
          )}
        </div>
        <div className="mt-3 sm:mt-4 text-center">
          {!isResetPassword && (
            <button
              onClick={toggleAuthMode}
              className="text-xs sm:text-sm text-gray-400 hover:text-gray-300 focus:outline-none transition duration-200"
            >
              {isLogin ? "New here? Create an account" : "Already have an account? Log in"}
            </button>
          )}
        </div>
        {message && (
          <p className={`mt-3 sm:mt-4 text-xs sm:text-sm text-center ${message.includes('error') ? 'text-red-400' : 'text-green-400'}`}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
}

export default AuthModal;

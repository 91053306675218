import React from 'react';

const ImageViewModal = ({ imageUrl, onClose }) => {
  const handleDownload = async (e) => {
    e.stopPropagation();
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'image.jpg'; // You can customize the filename here
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={onClose}>
      <div className="relative w-full max-w-3xl max-h-[90vh] overflow-hidden px-4 sm:px-0 flex justify-center items-center">
        <div className="relative">
          <img
            src={imageUrl}
            alt="Full size"
            className="max-w-full max-h-[80vh] h-auto rounded-lg object-contain"
            onClick={(e) => e.stopPropagation()}
          />
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-white bg-gray-800 rounded-full p-1.5 sm:p-2 hover:bg-gray-700 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-6 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <button
            onClick={handleDownload}
            className="absolute bottom-2 right-2 text-white bg-gray-800 bg-opacity-50 rounded-lg px-2 py-1 sm:px-3 sm:py-2 hover:bg-opacity-75 transition-opacity flex items-center space-x-1 sm:space-x-2 text-xs sm:text-base"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 sm:h-5 sm:w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
            <span>Download</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageViewModal;
